/* @import url(//db.onlinewebfonts.com/c/5ab304ade0d4f62d027d12464ca69443?family=Apple+Color+Emoji); */

body {
  height: 100vh;
}

#root {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 100%;
}

.viewport-header {
  position: relative;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: "Grooven-Shine";
  src: local("Grooven-Shine"),
    url(./assets/Grooven-Shine.otf) format("opentype");
}

@font-face {
  font-family: "DreamyLand";
  src: local("Arvo"), url(./assets/DreamyLandMedium.ttf) format("truetype");
}

@font-face {
  font-family: "Arvo";
  src: local("Arvo"), url(./assets/Arvo/Arvo-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "ArvoBold";
  src: local("Arvo"), url(./assets/Arvo/Arvo-Bold.ttf) format("truetype");
}

.soy-logo-img {
  width: 100px;
  height: 72px;
  margin-left: 5px;
  filter: drop-shadow(0 0 0.75rem black);
}

.navbar-expand {
  margin-left: 97px;
  margin-right: 97px;
  border-bottom: solid 1px #888;
}

@media only screen and (max-width: 700px) {
  .navbar-expand {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #888;
  border-radius: 48px;
  background-color: #222;
  border: none;
  opacity: 1 !important;
}

.btn-primary {
  outline: none !important;
  border: none !important;
}

.btn-primary:focus {
  background-color: #fff;
  color: #000;
  outline: none;
  border: none !important;
}

a:hover {
  color: #e5c100;
}

.external-link:hover {
  color: #e5c100;
}

.container {
  max-width: 100%;
}

.main-box {
  height: 45vh !important;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.main-button {
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding: 13px 40px 15px;
  border-radius: 30px;
  background-color: #fff;
  background-color: #fff;
  font-family: Arvo;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.main-connect-button {
  width: 270px;
  height: 90px;
  border-radius: 48px;
  background-color: #fff;
  font-family: Arvo;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;

  box-shadow: 2px 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.main-button:hover {
  background-color: #a89ab0;
}

.studio-title {
  font-family: Grooven-Shine;
  font-size: 28px;
  margin-top: 32px !important;
  margin-left: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.nav-bar-brand-title {
  margin-top: 30px !important;
  display: inline-block;
}

.nav-bar-brand-title-dark {
  margin-top: 30px !important;
  display: inline-block;
  color: black;
}

.studio-title-xs {
  font-family: Grooven-Shine;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.connect-top {
  width: 171px;
  height: 54px;
  border-radius: 30px;
  background-color: #fff;
  font-family: Arvo;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.connect-top:focus:not(:focus-visible) {
  outline: none !important;
  border: none !important;
}

.connect-top:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none;
}

.external-link {
  width: 16px;
  height: 16px;
  padding: 1px;
  object-fit: contain;
  color: #a89ab0;
  margin-bottom: 3px !important;
}

.balance-top {
  width: 183px;
  margin-top: 15px !important;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: Arvo;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: white;
  margin-right: 10px !important;
}

.connect-top:disabled,
.connect-top.disabled {
  width: 171px;
  height: 54px;
  border-radius: 30px;
  background-color: #222;
  border: none;
}

.connect-top:hover {
  background-color: #a89ab0;
}

.soy-alert {
  border-radius: 10px !important;
  border: solid 3px rgba(136, 136, 136, 0.5) !important;
  background-color: #222 !important;
  color: white;
  padding-right: 1rem !important;
}

.alert-link-soy {
  color: #a89ab0;
  text-decoration: none !important;
}

.soy-alert-sub {
  font-family: Arvo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #888;
}

.soy-alert .alert-heading {
  font-family: Arvo;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.soy-alert .btn-close {
  color: white;
  background: transparent url("./assets/cross-big.svg") center/1em auto
    no-repeat;
}

.alert-soy {
  font-family: Arvo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.soy-img {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;

  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.6);
}

.soy-img:hover {
  color: #888;
  cursor: pointer;
}

.soy-info {
  font-family: Arvo;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.list-soy {
  margin-left: 20%;
  margin-right: 20%;
}

.col-soy {
  min-height: 150px;
  min-width: 150px;
  margin: 0 10px;
}

@media only screen and (max-width: 800px) {
  .col-soy {
    min-height: 80px;
    min-width: 80px;
    margin: 0 10px;
  }
}

@media only screen and (max-width: 1400px) {
  .list-soy {
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media only screen and (max-width: 1200px) {
  .list-soy {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (max-width: 900px) {
  .list-soy {
    margin-left: 0;
    margin-right: 0;
  }
}

.menu-button {
  margin-left: 5px !important;
  margin-right: 5px !important;
  border-radius: 30px;
  background-color: white;
  padding: 13px 40px 15px;
  box-shadow: 2px 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.menu-button a {
  padding: 13px 10px 15px;
  font-family: Arvo;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  text-decoration: none;
}

.menu-button:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

.menu-button:disabled a {
  color: white;
}
.menu-button:disabled {
  background-color: transparent !important;
  border-image-source: linear-gradient(
    237deg,
    rgba(245, 167, 20, 0.25) 102%,
    rgba(209, 43, 199, 0.25) 54%,
    rgba(39, 168, 71, 0.25) 0%
  ) !important;
  border-image-slice: 1 !important;
  background-image: linear-gradient(
      170deg,
      rgba(182, 214, 63, 0.5) -5%,
      rgba(208, 95, 95, 0.5) 61%,
      rgba(78, 127, 247, 0.5) 135%
    ),
    linear-gradient(
      237deg,
      rgba(245, 167, 20, 0.25) 102%,
      rgba(209, 43, 199, 0.25) 54%,
      rgba(39, 168, 71, 0.25) 0%
    );
  background-origin: border-box;
}

img.Cross-big {
  width: 40px;
  height: 40px;
  padding: 6px;
  object-fit: contain;
  cursor: pointer;
}

img.Cross-big-top {
  width: 40px;
  height: 40px;
  padding: 6px;
  margin-top: 9px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

body.modal-open > :not(.modal) {
  /* -webkit-filter: blur(5px) brightness(0.2);
  -moz-filter: blur(5px) brightness(0.2);
  -o-filter: blur(5px) brightness(0.2);
  -ms-filter: blur(5px) brightness(0.2);
  filter: blur(5px) brightness(0.2); */
}

.pfp-modal {
  border: none !important;
  outline: none !important;
}

.pfp-modal .modal-content .modal-body {
  padding-right: 90px;
  padding-left: 90px;
}

.pfp-modal-title {
  font-family: Arvo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.modal-title-button {
  margin-left: -40px;
}

.main-action-button {
  padding: 13px 40px 15px;
  border-radius: 30px;
  background-color: #fff;
  font-family: Arvo;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  box-shadow: 2px 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.main-action-button:hover {
  background-color: #a89ab0;
}

.soy-banner-box {
  width: 600px;
  height: 320px;
  margin-top: 10px;
  padding: 0 0 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  border-radius: 10px;
}

.soy-phone-box {
  width: 360px;
  height: 640px;
  margin-top: 10px;
  padding: 0 0 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  border-radius: 10px;
}

.soy-banner-canvas {
  width: 600px;
  height: 200px;
}

.btn-close {
  color: #fff !important;
}

.special_modal .modal-body {
  background-color: transparent;
}

.special_modal .modal-header {
  background-color: transparent;
  border-bottom: none;
}

.special_modal .modal-content {
  border: none !important;
  outline: none !important;
  background-color: transparent;
}

.message-list {
  position: absolute;
  display: flexbox;
  right: 1%;
  top: 70px;
  z-index: 1100;
}

.connect-button {
  min-width: 120px;
}

.metamask-icon {
  width: 40px;
  height: 40px;
}

.row-border {
  border-bottom: solid 1px #979797;
}

.row {
  --bs-gutter-x: 0 !important;
}

.wallet-connect-hover:hover {
  background-color: rgba(218, 218, 218, 0.2);
  cursor: pointer;
}

.wallet-connect-title {
  font-family: Arvo;
  font-size: 20px !important;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.wallet-connect-sub {
  font-family: Arvo;
  font-size: 14px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #979797;
}

.twt-pfp {
  z-index: 1000;
  width: 142px;
  height: 142px;
  margin-top: -81px;
}

.twt-title {
  margin-left: 15px;
  font-family: Arvo;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.twt-sub {
  margin-left: 15px;
  margin-bottom: 10px !important;
  font-family: Arvo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #888;
}

.pfp-banner-canvas {
  border-radius: 10px;
}

.pfp-banner-canvas2 {
  max-width: 500px;
}

.pfp-banner-canvas .konvajs-content {
  border-radius: 10px;
  align-items: center !important;
  justify-content: center !important;
}

.content {
  position: relative;
  padding: 5px;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  border-radius: 50% !important;
  width: 100%;
  height: 100%;
}

.content .content-overlay-visible {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  border-radius: 50% !important;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content-details-visible {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 1;
}

.content-details-text {
  font-family: Arvo;
  font-size: 100px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.content-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
}
.content-progress-bar svg {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

/*
Hide Progress Bar trail
*/
.content-progress-bar .rc-progress-circle-trail {
  display: none;
}

.image-item {
  padding: 20px 0 20px 20px;
}


.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.download-button {
  width: 175px;
}

.promo-image {
  max-width: 500px;
}

.info-img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.pfp-img {
  max-width: 500px;
  z-index: 1100;
}

.pfp-img-bg {
  position: absolute;
  text-align: center;
  /* padding-left: 1em;
  padding-right: 1em; */
  width: 100%;
  max-width: 500px;

  /* top: 50%;
  left: 50%; */
}

.browse-img {
  width: 50px;
  height: 50px;
  margin-left: 25px;
  margin-right: 25px;
}

.browse-img-disabled {
  opacity: 0.2;
  width: 50px;
  height: 50px;
  margin-left: 25px;
  margin-right: 25px;
}

.browse-img:hover {
  cursor: pointer;
}

.wallet-connect-hover:hover {
  background-color: rgba(218, 218, 218, 0.2);
  cursor: pointer;
}

.wallet-connect-title {
  font-family: Arvo;
  font-size: 20px !important;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.wallet-connect-sub {
  font-family: Arvo;
  font-size: 14px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #979797;
}

.trusted-modal .modal-content .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.trusted-modal .modal-content .row > * {
  padding-left: 0px;
  padding-right: 0px;
}

.trusted-modal .modal-content {
  background-color: #222 !important;
  border-radius: 10px;
  border: solid 3px rgba(136, 136, 136, 0.5);
}

.trusted-modal-content .modal-content {
  width: 900px !important;
  min-width: 900px !important;
}

.trusted-modal-content .modal-body {
  padding: 0px 110px 0px 110px !important;
}

.modal-button-margin {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.soy-picker-modal .modal-content {
  background-color: transparent;
  border: none;
}

.soy-picker-modal .modal-content .modal-body {
  background-color: #222 !important;
  border-radius: 10px;
  border: solid 3px rgba(136, 136, 136, 0.5);
}
.soy-picker-modal .modal-content .modal-header {
  background-color: transparent;
  border-bottom: none;
}

.story-title-edit-label {
  font-family: DreamyLand;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
}

.story-title-edit {
  font-family: DreamyLand;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.story-body-edit {
  font-family: Arvo;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.soy-story-img {
  width: 50%;
  margin-top: -150px;
  /* margin-left: -25%; */
}

.soy-story-form {
  padding-left: 12%;
  padding-right: 12%;
}

.copy-link {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-family: Arvo;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffe900;
  padding-left: 15px;
  text-decoration: underline;
}

.soy-story-title {
  font-family: DreamyLand;
  font-size: 50px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  padding-left: 5rem;
  padding-right: 5rem;
}

.soy-story-sub-title {
  font-family: Arvo;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin-top: 10px;
  margin-bottom: 25px;
  padding-left: 5rem;
  padding-right: 5rem;
}

.top-connect-box {
}

/* img {
  -webkit-filter: grayscale(100%); 
  filter: grayscale(100%);
} 
*/

@media only screen and (max-width: 700px) {
  .top-connect-box {
    margin-left: auto;
    margin-right: 0;
  }
}

.soy-story-text {
  font-family: Arvo;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  padding-left: 5rem;
  padding-right: 5rem;
  max-height: 250px;
}

.soy-story-card {
  border-radius: 10px;
  min-width: 600px;
  padding-bottom: 90px;
  max-width: 600px;
  margin-top: 100px;
  margin-bottom: 70px;
  /* box-shadow: 2px 10px 10px 0 rgba(0, 0, 0, 0.2); */
  background-color: #fff;
}

@media only screen and (max-width: 700px) {
  .soy-story-text {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .soy-story-sub-title {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .soy-story-title {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .soy-story-card {
    min-width: 60px;
  }
}

.soy-card-wrap {
  width: fit-content;
  /* To adjust the height as well */
  height: fit-content;
}

.soy-story-img-main {
  width: 50%;
  margin-top: -120px;
  /* margin-left: -25%; */
}

.soy-edit-icon {
  width: 24px;
  height: 24px;
  margin-top: 30px;
  margin-right: 16px;
}

.soy-share-icon-flat {
  width: 24px;
  height: 24px;
}

.soy-share-icon {
  width: 24px;
  height: 24px;
  margin-top: 30px;
  margin-right: 30px;
}

.soy-share-icon:hover,
.soy-edit-icon:hover {
  cursor: pointer;
}

.tooltip {
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.addr-link {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}

.soy-share-image {
  height: 450px;
  width: auto;
}
.text-divider {
  display: flex;
  align-items: center;
  --text-divider-gap: 1rem;

  color: white;
  font-family: Grooven-Shine;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.text-divider::before,
.text-divider::after {
  content: "";
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}

.offcanvas-title {
  font-family: Grooven-Shine;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.navbar-brand {
  font-family: Grooven-Shine;
  font-size: 28px;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.nav-link {
  font-family: Arvo;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}